import colors from './colors'

const settings = {
  title: 'revelio',

  theme: {
    primaryColor: colors.find(color => color.id === 'blue'),
    secondaryColor: colors.find(color => color.id === 'red'),
    type: typeof window !== 'undefined' ? (!!window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') : 'light'
  },
};

export default settings;