import '../styles/app.css'
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { setTheme } from '../../src/theme';

import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  // initializeFirestore, CACHE_SIZE_UNLIMITED, 
  // persistentLocalCache, persistentMultipleTabManager,
  getFirestore, doc, getDoc, setDoc, enableMultiTabIndexedDbPersistence } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
//import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";

import { useCookies } from 'react-cookie';
//import { init as initApm } from '@elastic/apm-rum'
import { get } from "lodash";
import readingTime from 'reading-time';
// import useMediaQuery from '@mui/material/useMediaQuery';

export const ColorModeContext = React.createContext({ toggleColorMode: (current_mode) => { } });

export const AppStateContext = React.createContext({
  state: {},
  setState: () => { },
  layoutState: {},
  setLayoutState: () => { },
  openSnackbar: () => { },
  firebaseApp: null,
  firestore: null,
  functions: null,
  storage: null,
});

function makeid(length) {
  var result = '';
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function TopLayout(props) {

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined" && typeof window.location !== "undefined" && window.location.hostname == "revelio.io"

  const firebaseApp = initializeApp({
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
  });

  // const firestore = initializeFirestore(firebaseApp,
  //   {
  //     localCache:
  //       persistentLocalCache(/*settings*/{ tabManager: persistentMultipleTabManager() }),
  //     cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  //   });
  const firestore = getFirestore(firebaseApp);
  const functions = getFunctions(firebaseApp, 'europe-west1');
  const storage = getStorage(firebaseApp);

  const [cookies, setCookie] = useCookies(['themeType', 'analyticsPermission']);

  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState();

  const [state, setState] = useState(
    {
      signInDialog: {
        open: false
      },

      isPerformingAuthAction: {
        value: false,
        signedOut: true
      },

      isSignedIn: {
        value: false,
        user: null
      },

      creator: false,
      username: 'public',
      avatar: "",

      contactSupportDialog: false,
    }
  );

  const [layoutState, setLayoutState] = useState(
    {
      drawer: {
        open: false, //drawerOpen
      },

      snackbar: {
        autoHideDuration: 0,
        message: '',
        open: false
      },

      bottomMenu: {
        open_bottom_menu: false,
        anchorEl: null,
      },

      pendingStatus: false,
    }
  )

  useEffect(() => {
    if (!!cookies && !!cookies.themeType) {
      setMode(cookies.themeType)
      // } else if (!!prefersDarkMode) {
      //   setMode('dark')
    } else {
      setMode('light')
    }
  }, [])

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light'
          setCookie('themeType', newMode, { path: '/', domain: window.location.hostname, secure: window.location.hostname !== 'localhost', sameSite: 'strict', maxAge: 8 * 60 * 60 })
          return newMode
        });
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      setTheme(!!mode ? mode : 'light'),
    [mode],
  );

  // useEffect(() => {
  //   let isCancelled = false;
  //   if (!!!isCancelled) {
  //     if (process.env.NODE_ENV === 'production' && isBrowser) {
  //       try {
  //         // const appCheck = firebase.appCheck();
  //         // // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  //         // // key is the counterpart to the secret key you set in the Firebase console.
  //         // appCheck.activate('6Lc26fUUAAAAAIB0SH_wvKe0fg2nnCIXN0jaXswl');

  //         //getPerformance(firebaseApp);

  //         enableMultiTabIndexedDbPersistence(firestore)
  //           .catch(function (err) {
  //             if (err.code === 'failed-precondition') {
  //               // Multiple tabs open, persistence can only be enabled
  //               // in one tab at a a time.
  //               console.log("Cannot enable offline database because multiple tabs are open.")
  //             } else if (err.code === 'unimplemented') {
  //               // The current browser does not support all of the
  //               // features required to enable persistence
  //               console.log("Cannot enable offline database because your browser doesn't support it.")
  //             }
  //           });

  //         // initApm({

  //         //   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  //         //   serviceName: 'revelio',

  //         //   // Set custom APM Server URL (default: http://localhost:8200)
  //         //   serverUrl: 'https://8840fe2d641242f3bb0fff9f129c0d81.apm.europe-west1.gcp.cloud.es.io',

  //         //   //apiVersion: 3,

  //         //   // Set service version (required for sourcemap feature)
  //         //   serviceVersion: '1.0',

  //         //   breakdownMetrics: true,

  //         //   environment: `${process.env.NODE_ENV}`
  //         // })
  //       }
  //       catch (error) {
  //         console.log(error)
  //       }
  //     }
  //   }

  //   return () => {
  //     isCancelled = true;
  //   }
  // }, [])

  const openSnackbar = (message) => {
    setLayoutState(currentState => {
      return {
        ...currentState, ...{
          snackbar: {
            autoHideDuration: readingTime(message).time * 2,
            message,
            open: true
          }
        }
      }
    });
  };

  const appState = {
    state: state,
    setState: setState,
    layoutState: layoutState,
    setLayoutState: setLayoutState,
    openSnackbar: openSnackbar,
    firebaseApp: firebaseApp,
    firestore: firestore,
    functions: functions,
    storage: storage,
  }

  const [firebaseAnalytics, setFirebaseAnalytics] = useState(null)
  const cookies_analytics_permission = !!cookies && cookies.analyticsPermission === 'true';

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && isBrowser) {
      if (!!cookies_analytics_permission) {
        if (!!!firebaseAnalytics) {
          setFirebaseAnalytics(getAnalytics());
        }
      } else {
        if (!!firebaseAnalytics) setFirebaseAnalytics(null)
      }
    }
  }, [cookies_analytics_permission])

  const checkUsernameAvailability = async (email) => {

    setLayoutState(currentState => {
      return {
        ...currentState, ...{
          pendingStatus: true
        }
      }
    })
    openSnackbar("Creating a username for you...")

    let username = email.replace(/^([a-z0-9._-]+)@.*/, '$1')
    let available = false;

    const checkAvailability = httpsCallable(functions, 'checkUsernameAvailability');

    while (available === false) {
      //console.log("CHECKING USERNAME: ", username)
      await checkAvailability({ username: username })
        .then((result) => {
          if (result.data.available !== null) {
            available = result.data.available;
          } else {
            available = 'ERROR'
          }
          return true
        })
        .catch(error => {
          console.error("Error checking if username is available: ", error)
          available = 'ERROR'
          return false
        })

      if (!available) {
        username = `${username}-${makeid(5)}`
      }
    }

    if (available === true) {
      return username
    } else {
      return 'ERROR'
    }
  }

  const createUserInFirestore = async (isSignedIn) => {
    const userId = isSignedIn.user.uid;

    const username = await checkUsernameAvailability(isSignedIn.user.email);

    //console.log("GOT USERNAME: ", username)

    if (username === 'ERROR') {
      console.error("Error creating new user")
      return { creator: false, username: 'public', avatar: "" }
    }

    return setDoc(doc(firestore, "users", userId),
      {
        name: isSignedIn.user.displayName,
        email: isSignedIn.user.email,
        avatar: !!isSignedIn.user.photoURL ? isSignedIn.user.photoURL : "",
        username: username,
        created_date: new Date(),
      }, { merge: true })
      .then(() => {
        //console.log("Created new user in Database")

        setLayoutState(currentState => {
          return {
            ...currentState, ...{
              pendingStatus: false
            }
          }
        })

        openSnackbar(`Welcome @${username}!`)
        return { creator: false, username: username, avatar: !!isSignedIn.user.photoURL ? isSignedIn.user.photoURL : "" }
      })
      .catch(e => {
        console.error("Error creating user in Database: ", userId, e)
        return { creator: false, username: 'public', avatar: "" }
      })

  }

  async function getCustomClaimRole() {
    const auth = getAuth(firebaseApp);
    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    let isCancelled = false;

    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, user => {
      if (!isCancelled) {
        if (!!user && !!user?.uid) {
          if (!!!get(state, 'isSignedIn.value', false)) {
            getDoc(doc(firestore, 'users', user.uid))
              .then(async (docSnap) => {
                if (!!!docSnap.exists()) {
                  const new_user = createUserInFirestore({ value: !!user, user: user })
                  return new_user
                } else {
                  return docSnap.data()
                }
              })
              .then(async docSnap => {
                //console.log("DEBUG: ", doc)
                if (!isCancelled) {
                  const revelio_creator = await getCustomClaimRole();
                  //console.log("DEBUG: ", revelio_creator, doc)
                  const creator = revelio_creator !== undefined && ['creator_pro', 'creator_starter', 'creator_advanced'].includes(revelio_creator);
                  setCookie('analyticsPermission', !!docSnap.analytics_permission ? 'true' : 'false', { path: '/', domain: window.location.hostname, secure: window.location.hostname !== 'localhost', sameSite: 'strict', maxAge: 180 * 24 * 60 * 60 })
                  setState(currentState => {
                    return {
                      ...currentState, ...{
                        isPerformingAuthAction: {
                          ...currentState.isPerformingAuthAction,
                          value: false,
                        },
                        isSignedIn: {
                          value: !!user,
                          user: user
                        },
                        creator: creator,
                        creator_type: revelio_creator,
                        stripeAccountId: docSnap.stripeAccountId,
                        stripeAccountCountry: docSnap.stripe_connect?.country,
                        username: docSnap.username,
                        avatar: docSnap.avatar,
                      }
                    }
                  })
                }
              })
              .catch(e => {
                console.error("Cannot fetch user data: ", e)
                openSnackbar("Sorry, something went wrong. Please refresh the page and try signing it again.")
              })
          }
        } else {
          // setCookie('analyticsPermission', undefined, { path: '/', domain: window.location.hostname, secure: window.location.hostname !== 'localhost', sameSite: 'strict', maxAge: 180 * 24 * 60 * 60 })
          setState(currentState => {
            return {
              ...currentState, ...{
                isPerformingAuthAction: {
                  ...currentState.isPerformingAuthAction,
                  value: false,
                },
                isSignedIn: {
                  value: !!user,
                  user: user
                },
                creator: false,
                username: 'public',
                avatar: "",
                stripeAccountCountry: null,
                stripeAccountId: null,
              }
            }
          })
        }
      }
    })

    return () => {
      isCancelled = true;
    };

  }, [])

  return (
    <React.Fragment>
      <ColorModeContext.Provider value={colorMode}>
        <AppStateContext.Provider value={appState}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {props.children}
          </ThemeProvider>
        </AppStateContext.Provider>
      </ColorModeContext.Provider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};