/* eslint-disable import/prefer-default-export, react/prop-types */
// import "firebase/compat/firestore"
// import "firebase/compat/storage"
// import "firebase/app-check"
import "@fontsource/montserrat/500.css"
import "@fontsource/material-icons";
import "@fontsource/libre-baskerville/400.css"
import "@fontsource/libre-baskerville/700.css"
import React from "react"
import TopLayout from "./src/layouts/TopLayout";

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   `A new version of the Revelio website is available. ` + 
  //   `Reload to display the latest updates?`
  //   )

  //   if (answer === true) {
  //     window.location.reload()
  //   }
  // window.location.reload();
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <TopLayout {...props}>{element}</TopLayout>
}