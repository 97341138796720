import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import settings from './settings'

// A custom theme for this app
const palette = (mode) => ({
  primary: settings.theme.primaryColor.import,
  secondary: settings.theme.secondaryColor.import,
  mode: mode,
  error: {
    main: red.A400,
  },
  background: {
    paper: mode === 'dark' ? '#424242': '#fff', 
    default: mode === 'dark' ? '#424242': '#fff',
  },
})

const fontFamily = '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

const typography = {
    fontFamily: fontFamily,
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontFamily: fontFamily,
    },
    h2: {
      fontFamily: fontFamily,
    },
    h3: {
      fontFamily: fontFamily,
    },
    h4: {
      fontFamily: fontFamily,
    },
    h5: {
      fontFamily: fontFamily,
    },
    h6: {
      fontFamily: fontFamily,
    },
    subtitle1: {
      fontFamily: fontFamily,
    },
    subtitle2: {
      fontFamily: fontFamily,
    },
    body1: {
      fontFamily: fontFamily,
      letterSpacing: '0.01rem',
    },
    body2: {
      fontFamily: fontFamily,
      letterSpacing: '0.01rem',
    },
    button: {
      fontFamily: fontFamily,
    },
    caption: {
      fontFamily: fontFamily,
    },
    overline: {
      fontFamily: fontFamily,
    },
  };

const baseTheme = createTheme({
  palette: palette('light'),
  typography: typography,
});

const setTheme = (mode) => {
  const newBaseTheme = createTheme({
    palette: palette(mode),
    typography: typography
  })

  const newTheme = responsiveFontSizes(newBaseTheme);

  return newTheme
}

export { baseTheme, setTheme };